<script setup lang="ts">
const { updateCoords } = useMouseCoords();
</script>

<template>
  <main
    v-mouse-coords="updateCoords"
    class="flex flex-col justify-center items-center w-full h-screen gap-16 bg-ngray-7"
  >
    <minimal-content-section>
      <slot />
    </minimal-content-section>
  </main>
</template>
